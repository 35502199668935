import styled from "styled-components";

export const Section = styled.section`
  #blog .dots {
    display: none;
  }
  #blog .prev,
  .next {
    color: black;
    top: 20%;
  }
  .d-content-center {
    display: flex !important;
    justify-content: center;
    align-items: center;
  }
  .card-blog {
    width: 168px;
    margin: auto;
    height: 193px;
    position: relative;
    background-size: 100% 100%;
    background-position: center;
  }
  .card-blog .show-content {
    display: block;
  }
  .card-blog .show-content div,
  img {
    position: absolute;
    right: 0px;
  }
  .card-blog .show-content div {
    width: 120px;
    right: 25px;
    text-align: right;
    bottom: 17px;
  }
  .card-blog .show-content div hr {
    width: 108px;
    margin-right: -6px;
  }
  @media (min-width: 992px) {
    .card-blog {
      width: 200px;
      height: 225px;
      position: relative;
      background-size: cover;
      background-position: center;
    }
    .card-blog .show-content {
      display: none;
    }
    .card-blog:hover {
      .show-content {
        display: block;
      }
    }
    .card-blog .show-content div,
    img {
      position: absolute;
      right: 0px;
    }
    .card-blog .show-content div {
      width: 140px;
      right: 30px;
      text-align: right;
      bottom: 17px;
    }
    .card-blog .show-content div hr {
      width: 135px;
      margin-right: -6px;
    }
  }
`;
