import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import es from "./locales/es.json";
import esAr from "./locales/es-ar.json";
import esEc from "./locales/es-ec.json";
import esES from "./locales/es-es.json";
import esMX from "./locales/es-mx.json";
import esPE from "./locales/es-pe.json";

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    fallbackLng: "es",
    resources: {
      es: { translation: es },
      "es-AR": { translation: esAr },
      "es-EC": { translation: esEc },
      "es-ES": { translation: esES },
      "es-MX": { translation: esMX },
      "es-PE": { translation: esPE },
    },
    detection: {
      order: ["localStorage", "sessionStorage", "subdomain", "htmlTag"],
      lookupLocalStorage: "language",
      lookupSessionStorage: "language",
    },
  });

export default i18n;
