import styled from "styled-components";

export const Section = styled.section`
  padding-left: 0px;
  padding-right: 0px;
  background-size: 100%;
  background-repeat: no-repeat;

  ::-webkit-scrollbar {
    display: none;
  }
  .padding-banner-mobil {
    padding-left: 0px;
    padding-right: 0px;
  }
  .card {
    padding: 10px 2px;
    background: #ffffff;
    box-shadow: none;
    min-width: 338px;
    min-height: 620px;
    transition: all 0.5ms;
    margin-top: 0rem;
    border-radius: 20px;
    transition: all 0.2s ease-out;
  }

  #quote-modal .close {
    color: #868e96;
    z-index: 1;
  }

  .bg-mx-0 {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }

  .bg-px-0 {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  @media (min-width: 576px) {
    padding-top: 0;
  }
  @media (min-width: 768px) {
    background-size: 100% 100%;
    min-height: 50vh;
  }
  @media (min-width: 992px) {
    position: relative;

    .card {
      padding: 10px 2px;
      background: #ffffff;
      box-shadow: 0px 4px 26px rgba(0, 0, 0, 0.21);
      min-width: 338px;
      min-height: 620px;
      transition: all 0.5ms;
      margin-top: 3rem;
      border-radius: 20px;
      transition: all 0.2s ease-out;
    }
  }
`;

export const SectionSize = styled.div`
  justify-content: end;
  min-height: 109px;

  .content-resposive {
    max-width: 390px;
  }

  @media (min-width: 768px) {
    justify-content: center;
    min-height: 10vh;
  }
  @media (min-width: 992px) {
    justify-content: end;
    .container-countdown {
      min-height: 600px;
    }
  }
`;

export const Button = styled.button`
  width: 13.375rem;
  color: #ffffff;
  height: 3.125rem;
  background: #fdad00;
  line-height: 1.188rem;
  border-radius: 1.375rem;
`;

export const ModalContent = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ffffff;
  padding-top: 0px;

  @media (min-width: 768px) {
    bottom: -36%;
    padding-top: 30px;
  }
  @media (min-width: 1200px) {
    bottom: 0%;
    padding-top: 0;
  }
`;
