import { lazy, Suspense } from "react";
import { Banner } from "./components";

const AssistanceInformation = lazy(
  () =>
    import(
      "./components/assistance-information/assistance-information.component"
    )
);
const CategoriesPlans = lazy(
  () => import("./components/categories-plans/categories-plans.component")
);
const RatingsReviews = lazy(
  () => import("./components/ratings-reviews/ratings-reviews.component")
);
const Offices = lazy(() => import("./components/offices/offices.component"));
const OurAlliances = lazy(
  () => import("./components/our-alliances/our-alliances.component")
);
const Blogs = lazy(() => import("./components/blogs/blogs.component"));
const NeedHelp = lazy(
  () =>
    import("./components/why-do-you-need-help/why-do-you-need-help.component")
);

export default function Root(_props) {
  return (
    <div className="content-home">
      <Banner />

      <Suspense fallback={<div>Loading...</div>}>
        <AssistanceInformation />
      </Suspense>

      <Suspense fallback={<div>Loading...</div>}>
        <CategoriesPlans />
      </Suspense>

      <Suspense fallback={<div>Loading...</div>}>
        <RatingsReviews />
      </Suspense>

      <Suspense fallback={<div>Loading...</div>}>
        <NeedHelp />
      </Suspense>

      <Suspense fallback={<div>Loading...</div>}>
        <Offices />
      </Suspense>

      <Suspense fallback={<div>Loading...</div>}>
        <OurAlliances />
      </Suspense>

      <Suspense fallback={<div>Loading...</div>}>
        <Blogs />
      </Suspense>
    </div>
  );
}

declare global {
  namespace JSX {
    interface IntrinsicElements {
      "spv-quote": React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLElement>,
        HTMLElement
      >;
      "spv-contact-button": any;
    }
  }
}
