import React from "react";
import { ModalDialog } from "./modal.style-component";

export type ModalProps = {
  id: string;
  size?: ModalSize;
  children?: any;
  triggerId?: string;
  showCloseButton?: boolean;
};

export enum ModalSize {
  sm = "4",
  md = "6",
  lg = "10",
}

export default class Modal extends React.Component<ModalProps> {
  private modalRef: HTMLElement;
  private openModalEventName: string;
  private backdropModalElemetName: string;

  static defaultProps = {
    size: ModalSize.md,
    showCloseButton: true,
  };

  constructor(props: ModalProps) {
    super(props);

    this.openModalEventName = "openModal[" + props.id + "]";
    this.backdropModalElemetName = "backdropModal[" + props.id + "]";
  }

  initModal() {
    this.modalRef = document.getElementById(this.props.id);
    const triggerModal = document.getElementById(this.props.triggerId);

    if (triggerModal) {
      triggerModal.onclick = () => this.openModal();
    }

    const backdrop = document.getElementById(this.backdropModalElemetName);
    backdrop.onclick = (e) => {
      const classList: DOMTokenList = e.target["classList"];

      if (e.target == backdrop || classList.contains("close-modal"))
        this.closeModal();
    };

    window.addEventListener(this.openModalEventName, () => this.openModal());
  }

  openModal() {
    this.modalRef.style.display = "block";
    document.body.style.overflowY = "hidden";
  }

  closeModal() {
    this.modalRef.style.display = "none";
    document.body.style.overflowY = "initial";
  }

  componentDidMount() {
    this.initModal();
  }

  componentWillUnmount() {
    window.removeEventListener(this.openModalEventName, () => this.openModal());
  }

  render() {
    return (
      <ModalDialog id={this.props.id}>
        {this.props.showCloseButton && (
          <span
            onClick={() => this.closeModal()}
            className="close"
            title="Close Modal"
          >
            &times;
          </span>
        )}

        <div className="container-fluid" style={{ height: "100%" }}>
          <div
            id={this.backdropModalElemetName}
            className="row"
            style={{ height: "100%" }}
          >
            <div className={"col-xs-12 m-auto col-md-" + this.props.size}>
              <div className="modal-content">{this.props.children}</div>
            </div>
          </div>
        </div>
      </ModalDialog>
    );
  }
}
