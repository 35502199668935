import React from "react";
import { Section } from "./offices.style.component";
import { t } from "i18next";
import { OFFICESDATA } from "./offices.data";
import { Carousel } from "../../libs";

export default class offices extends React.Component {
  public domain = localStorage.getItem("spv_topLevelDomain");
  render() {
    return (
      <div>
        {this.domain !== "es" && (
          <Section className="mt-5">
            <div className="container">
              <p className="Gotham-bold text-center f-20 f-lg-28 f-xl-28">
                {t("ourOffices")}
              </p>
              <Carousel
                id="offices"
                automatic={true}
                interval={5000}
                swipe={true}
                mobileItems={1.2}
                webItems={3.2}
              >
                {OFFICESDATA.map((item, index) => (
                  <div
                    className="card-office p-3 lazyload"
                    style={{
                      backgroundImage:
                        "-webkit-gradient(linear, 0% 0%, 0% 100%, from(rgba(0, 0, 0, 0)), to(rgb(0 0 0))), url(" +
                        item.image +
                        ")",
                      backgroundSize: "100% 100%",
                    }}
                    key={index}
                  >
                    <div>
                      <p className="text-lh-blue mb-0 card-name-city Gotham-bold">
                        {item.city}
                      </p>
                      <p className="Gotham f-lg-20 f-xl-25 text-white mb-0">
                        {item.address}
                      </p>
                    </div>
                  </div>
                ))}
              </Carousel>
            </div>
          </Section>
        )}
      </div>
    );
  }
}
