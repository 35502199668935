export const RATINGSREVIEWS = [
  {
    icon: "https://res.cloudinary.com/pervolare-org/image/upload/v1714440858/spv/sites/images/latest-home/ratings-review/icon-gerardo_pjyeta.png",
    title: "Gerardo Pereyra - Trustpilot",
    title_xs: "Gerardo Pereyra",
    platform: "Trustpilot",
    description:
      "Tuve un problema con mi hijo, llame al número indicado, no solo hicieron teleconsulta sino que me enviaron un médico al hotel y receto muy correctamente. Si bien es un temas menos, destaco todo el seguimiento constante por diferentes métodos. Buen servicio.",
    stars:
      "https://res.cloudinary.com/pervolare-org/image/upload/v1714440856/spv/sites/images/latest-home/ratings-review/score-five_hayaf8.png",
  },
  {
    icon: "https://res.cloudinary.com/pervolare-org/image/upload/v1716412845/spv/sites/images/latest-home/ratings-review/icon-lisa_iamvvt.png",
    title: "Lisa Saavedra - Google ",
    title_xs: "Lisa Saavedra",
    platform: "Google",
    description:
      "Excelente y ágil servicio prestado por los asesores en 5 minutos pude hacer el trámite de la compra del seguro médico que necesito para estar tranquila en mis viajes y obtener la documentación que prefiero llevar en físico. Muchas gracias!",
    stars:
      "https://res.cloudinary.com/pervolare-org/image/upload/v1714440856/spv/sites/images/latest-home/ratings-review/four_phk6fq.png",
  },
  {
    icon: "https://res.cloudinary.com/pervolare-org/image/upload/v1716412845/spv/sites/images/latest-home/ratings-review/icon-elkin_bwier4.png",
    title: "Elkin Barcos - Trustpilot",
    title_xs: "Elkin Barcos",
    platform: "Trustpilot",
    description:
      "Buen servicio y buenos precios. Tuve un inconveniente en mi viaje a Francia y lograron solucionarme sin problema. Recomendados",
    stars:
      "https://res.cloudinary.com/pervolare-org/image/upload/v1714440856/spv/sites/images/latest-home/ratings-review/score-five_hayaf8.png",
  },
];
