import React, { useEffect, useRef, useState } from "react";
import { SwipeDetect, SwipeDetectDir } from "./swipedetect";
import useInterval from "./useinterval";
import {
  BannerSlide,
  CarouselBannerContainer,
  CarouselInner,
  ControlButton,
  ControlsContainer,
  Dot,
  DotContainer,
  PrevButtonContainer,
  NextButtonContainer,
} from "./carousel-banner.style-component";

type ImageSet = {
  web: string;
  tablet: string;
  mobile: string;
};

type CarouselBannerProps = {
  images: ImageSet[];
  autoPlay?: boolean;
};

const CarouselBanner: React.FC<CarouselBannerProps> = ({
  images,
  autoPlay = true,
}) => {
  const [currentSlide, setCurrentSlide] = useState<number>(0);
  const carouselRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (carouselRef.current) {
      const handleSwipe = (swipeDir: SwipeDetectDir) => {
        if (swipeDir === "left") {
          showSlide(currentSlide + 1);
        } else if (swipeDir === "right") {
          showSlide(currentSlide - 1);
        }
      };

      SwipeDetect.swipe(carouselRef.current, handleSwipe);
    }
  }, [currentSlide]);

  const showSlide = (index: number) => {
    const totalSlides = images.length;

    if (index >= 0 && index < totalSlides) {
      setCurrentSlide(index);
    } else if (index >= totalSlides) {
      setCurrentSlide(0);
    } else if (index < 0) {
      setCurrentSlide(totalSlides - 1);
    }
  };

  const nextSlide = () => {
    showSlide(currentSlide + 1);
  };

  const prevSlide = () => {
    showSlide(currentSlide - 1);
  };

  const renderSlides = () => {
    return images.map((image, index) => (
      <BannerSlide
        key={index}
        style={{
          backgroundImage: `url(${determineCurrentDeviceImage(image)})`,
        }}
      />
    ));
  };

  const renderDots = () => {
    return images.map((_, index) => (
      <Dot
        key={index}
        onClick={() => showSlide(index)}
        className={index === currentSlide ? "active" : ""}
      />
    ));
  };

  const determineCurrentDeviceImage = (imageSet: ImageSet): string => {
    const width = window.innerWidth;

    if (width > 992) {
      return imageSet.web;
    } else if (width > 600) {
      return imageSet.tablet;
    } else {
      return imageSet.mobile;
    }
  };

  const AUTO_PLAY_INTERVAL = 10000;

  useInterval(() => {
    if (autoPlay) {
      showSlide(currentSlide + 1);
    }
  }, AUTO_PLAY_INTERVAL);

  const scrollToSpvQuote = () => {
    const spvQuoteElement = document.getElementById("spv-quote-latest-home");

    if (spvQuoteElement) spvQuoteElement.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <CarouselBannerContainer>
      <CarouselInner
        onClick={scrollToSpvQuote}
        ref={carouselRef}
        style={{ transform: `translateX(${-currentSlide * 100}%)` }}
      >
        {renderSlides()}
      </CarouselInner>
      <ControlsContainer>
        <PrevButtonContainer>
          <ControlButton onClick={prevSlide}>
            {<span>&#10094;</span>}
          </ControlButton>
        </PrevButtonContainer>
        <NextButtonContainer>
          <ControlButton onClick={nextSlide}>
            {<span>&#10095;</span>}
          </ControlButton>
        </NextButtonContainer>
      </ControlsContainer>
      <DotContainer>{renderDots()}</DotContainer>
    </CarouselBannerContainer>
  );
};

export default CarouselBanner;
