import React from "react";
import { AccordionContainer } from "./accordion.style-component";

export type AccordionProps = {
  id: string;
  body: JSX.Element;
  header: JSX.Element;
};

export default class Accordion extends React.Component<AccordionProps> {
  static defaultProps = {};

  constructor(props: AccordionProps) {
    super(props);
  }

  componentDidMount() {
    const acc = document.getElementById("accordion-" + this.props.id);

    acc.addEventListener("click", function () {
      this.classList.toggle("active");

      const panel = this.nextElementSibling;

      if (panel["style"].maxHeight) {
        panel["style"].maxHeight = null;
      } else {
        panel["style"].maxHeight = panel.scrollHeight + "px";
      }
    });

    window["toggleAccordion"] = (id: string) =>
      id == "accordion-" + this.props.id && acc.click();
  }

  render() {
    return (
      <AccordionContainer>
        <button className="accordion" id={"accordion-" + this.props.id}>
          {this.props.header}
        </button>
        <div className="panel">{this.props.body}</div>
      </AccordionContainer>
    );
  }
}
