import styled from "styled-components";

export const Section = styled.section`
  .card {
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    border: 1px solid rgb(0 0 0 / 10%);
  }
  .icon-card {
    justify-content: center;
    display: flex;
    align-items: center;
  }
  .title-card {
    border: 2px solid #2855a0;
    border-radius: 50px;
    padding: 5px 10px 5px 25px;
    margin-left: -20px;
  }
  .img-fluid-card {
    width: 100%;
    height: 20%;
  }
  #carouselCategoriesPlan .dots {
    display: none;
  }
  @media (min-width: 992px) {
    .card {
      box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
      border-radius: 10px;
      border: 1px solid rgb(0 0 0 / 10%);
    }
  }
`;
