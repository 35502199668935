export const OFFICESDATA = [
  {
    image:
      "https://res.cloudinary.com/pervolare-org/image/upload/v1716409815/spv/sites/images/latest-home/offices/bg-bogota_lelyqw.png",
    city: "Bogotá",
    address: "Cl 81 #19a-38, Localidad de Chapinero, Bogotá",
  },
  {
    image:
      "https://res.cloudinary.com/pervolare-org/image/upload/v1716409815/spv/sites/images/latest-home/offices/bg-medellin_knmcsn.png",
    city: "Medellín",
    address: "Cl 10 #42-28, El Poblado, Medellín",
  },
  {
    image:
      "https://res.cloudinary.com/pervolare-org/image/upload/v1716409815/spv/sites/images/latest-home/offices/bg-pereira_daddko.png",
    city: "Pereira",
    address: "Av. 30 de Agosto #37-66  Pereira, Risaralda",
  },
  {
    image:
      "https://res.cloudinary.com/pervolare-org/image/upload/v1716409815/spv/sites/images/latest-home/offices/bg-quito_pmpitc.png",
    city: "Quito",
    address: "La Pinta E4-451 y Juan Leon Mera, Piso 2",
  },
  {
    image:
      "https://res.cloudinary.com/pervolare-org/image/upload/spv/sites/images/latest-home/offices/bg-lima-cmd_zugqma.png",
    city: "Lima",
    address: "Av. Paseo de la República 5895 piso 11, Miraflores",
  },
  {
    image:
      "https://res.cloudinary.com/pervolare-org/image/upload/v1716409816/spv/sites/images/latest-home/offices/mexico_zlqtdx.png",
    city: "Ciudad de México",
    address: "Av Chapultepec 480 Piso 11, oficina 03",
  },
];
