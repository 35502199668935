export type SwipeDetectDir = "left" | "right";
export type SwipeDetectCallback = (swipedir: SwipeDetectDir) => void;

export class SwipeDetect {
  static swipe(el: HTMLElement, callback: SwipeDetectCallback): void {
    let touchsurface = el,
      startX: number,
      startY: number,
      distX: number,
      distY: number,
      threshold = 150, //required min distance traveled to be considered swipe
      restraint = 100, // maximum distance allowed at the same time in perpendicular direction
      allowedTime = 300, // maximum time allowed to travel that distance
      elapsedTime: number,
      startTime: number,
      handleswipe = callback;

    touchsurface.addEventListener(
      "touchstart",
      (e) => {
        // if (e.cancelable) {
        //   e.preventDefault();
        // }

        let touchobj = e.changedTouches[0];
        distX = 0;
        startX = touchobj.pageX;
        startY = touchobj.pageY;
        startTime = new Date().getTime(); // record time when finger first makes contact with surface
      },
      false
    );

    touchsurface.addEventListener(
      "touchmove",
      (e) => {
        // e.preventDefault(); // prevent scrolling when inside DIV
      },
      false
    );

    touchsurface.addEventListener(
      "touchend",
      (e) => {
        let touchobj = e.changedTouches[0];
        distX = touchobj.pageX - startX; // get horizontal dist traveled by finger while in contact with surface
        distY = touchobj.pageY - startY; // get vertical dist traveled by finger while in contact with surface
        elapsedTime = new Date().getTime() - startTime; // get time elapsed

        if (elapsedTime <= allowedTime) {
          // first condition for awipe met
          if (Math.abs(distX) >= threshold && Math.abs(distY) <= restraint) {
            // 2nd condition for horizontal swipe met
            handleswipe(distX < 0 ? "left" : "right"); // if dist traveled is negative, it indicates left swipe
          }
        }
        // e.preventDefault();
      },
      false
    );
  }
}
