import styled from "styled-components";

export const CarouselBannerContainer = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 195px;
  @media (min-width: 768px) {
    height: 420px;
  }
  @media (min-width: 992px) {
    position: absolute;
    top: 0;
    left: 0;
    height: 716px;
  }
`;

export const CarouselInner = styled.div`
  display: flex;
  transition: transform 0.5s ease;
  width: 100%;
  height: 100%;
`;

export const BannerSlide = styled.div`
  flex: 0 0 100%;
  position: relative;
  background-size: 100% 100%;
  background-position: center;
`;

export const ControlsContainer = styled.div`
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  top: 50%;
  transform: translateY(-50%);
`;

export const ControlButton = styled.button`
  background: transparent;
  border: none;
  font-size: 21px;
  cursor: pointer;
  font-weight: bold;
  color: black;
  @media (min-width: 992px) {
    display: none;
  }
`;

export const DotContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 10px;
  position: absolute;
  bottom: 20px;
  width: 100%;
`;

export const Dot = styled.span`
  height: 10px;
  width: 10px;
  background-color: white;
  border-radius: 50%;
  margin: 0 5px;
  cursor: pointer;
  width: 15px;
  height: 15px;
  &.active {
    background: #00388b;
    border: white solid 1px;
  }
`;

export const PrevButtonContainer = styled.div`
  position: absolute;
  left: 10px;
  border: 1px solid black;
  border-radius: 50px;
  background-color: white;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.6;
  &:hover {
    background-color: white;
    opacity: 0.9;
  }
  @media (min-width: 992px) {
    display: none;
  }
`;

export const NextButtonContainer = styled.div`
  position: absolute;
  right: 10px;
  border: 1px solid black;
  border-radius: 50px;
  background-color: white;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.6;
  &:hover {
    background-color: white;
    opacity: 0.9;
  }
  @media (min-width: 992px) {
    display: none;
  }
`;
