import React from "react";
import { Section } from "./ratings-reviews.style.component";
import { t } from "i18next";
import { RATINGSREVIEWS } from "./ratings-reviews-data";
import { Carousel } from "../../libs";

export default class ratingsReviews extends React.Component {
  render() {
    return (
      <Section className="mt-5" style={{ backgroundColor: "#E8F1FF" }}>
        <div className="container pt-5 pb-4 d-none d-md-block">
          <p className="text-center Gotham-bold f-20 f-lg-28 f-xl-28 mb-4">
            {t("ourClientsRecommendUs")}
          </p>
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-7 col-lg-7">
              {RATINGSREVIEWS.map((item, index) => (
                <div
                  className="mb-3 d-flex"
                  style={{ backgroundColor: "#FFFFFF", borderRadius: "20px" }}
                  key={index.toString()}
                >
                  <div className="d-inline-block pr-4 icon-lg">
                    <img
                      className="icon-border lazyload"
                      src={item.icon}
                      alt="icon"
                      width={70}
                      height={70}
                    />
                  </div>
                  <div className="d-inline-block w-80">
                    <div className="mt-3">
                      <p className="Gotham-bold f-16 text-lh-blue d-inline-block">
                        {item.title}
                      </p>
                      <img
                        className="d-inline-block lazyload"
                        style={{ float: "right" }}
                        width={152}
                        height={24}
                        src={item.stars}
                        alt="stars"
                      />
                    </div>
                    <p className="Oxygen f-14">{item.description}</p>
                  </div>
                </div>
              ))}
            </div>
            <div className="col-xs-12 col-sm-12 col-md-5 col-lg-5 score">
              <img
                className="lazyload"
                src="https://res.cloudinary.com/pervolare-org/image/upload/v1714438145/spv/sites/images/latest-home/ratings-review/score_qs8oop.webp"
                alt="score"
                width={348}
                height={241}
              />
            </div>
          </div>
        </div>
        <div className="container pt-4 pb-4 d-md-none">
          <div className="text-center mb-3">
            <p className="Gotham-bold f-20 px-4">
              {t("ourClientsRecommendUs")}
            </p>
            <img
              className="lazyload"
              src="https://res.cloudinary.com/pervolare-org/image/upload/v1714438145/spv/sites/images/latest-home/ratings-review/score_qs8oop.webp"
              alt="score"
              width="50%"
              height="50%"
            />
          </div>
          <Carousel
            id="carouselRatings"
            automatic={true}
            interval={5000}
            swipe={true}
            mobileItems={1.2}
          >
            {RATINGSREVIEWS.map((item, index) => (
              <div className="card text-center b-card p-3" key={index}>
                <img
                  className="icon-border-xs lazyload"
                  src={item.icon}
                  alt="icon"
                  width={74}
                  height={74}
                />
                <p className="Gotham-bold f-16 text-lh-blue mb-0">
                  {item.title_xs}
                </p>
                <p className="Gotham-bold f-16">{item.platform}</p>
                <p className="Gotham f-14 text-left" style={{ height: "50%" }}>
                  {item.description}
                </p>
                <div>
                  <img
                    className=""
                    width={152}
                    height={24}
                    src={item.stars}
                    alt="stars"
                  />
                </div>
              </div>
            ))}
          </Carousel>
        </div>
      </Section>
    );
  }
}
