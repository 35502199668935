import styled from "styled-components";

export const Section = styled.section`
  .card-img-center {
    justify-content: center;
    align-items: center;
  }
  .icon-one {
    width: 26px;
    height: 26px;
  }
  .icon-card {
    width: 26px;
    height: 20px;
  }
  .border-card {
    border-radius: 10px;
    border: 2px solid #2855a0;
    display: flex;
    justify-content: center;
    padding: 15px 10px;
    height: 88px;
    background: white;
  }
  .box-shadow-card {
    box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.1);
    background: #e8f1ff;
  }
  .img-emergency {
    width: 91%;
  }
  .d-emergency-icon {
    display: block;
  }
  .align-self {
    align-self: center;
  }
  .b-flags {
    border-radius: 3px;
  }
  @media (min-width: 992px) {
    .icon-one {
      width: 49px;
      height: 49px;
    }
    .icon-card {
      width: 52px;
      height: 39px;
    }
    .border-card {
      border-radius: 0px;
      border: none;
      display: block;
      justify-content: none;
      padding: 0px;
      height: auto;
    }
    .border-r-card {
      border-right: 4px solid #2855a0;
    }
    .box-shadow-card {
      box-shadow: 0px 5px 4px 0px #19213d33;
      background: transparent;
    }
    .shadow {
      box-shadow: 0px 5px 4px 0px #19213d33;
    }
    .d-emergency-icon {
      display: flex;
    }
  }
`;
