export const OURALLIANCES = [
  {
    image:
      "https://res.cloudinary.com/pervolare-org/image/upload/v1696012953/spv/sites/images/new-home/partners/Logo-TAS_k3oysv.png",
    alt: "icon TAS",
    width: "100",
    widthMobil: "100",
    heightMobil: "40",
    height: "50",
    domains: [],
  },
  {
    image:
      "https://res.cloudinary.com/pervolare-org/image/upload/v1696012953/spv/sites/images/new-home/partners/logo-vtc_uckbpt.png",
    alt: "icon VTC",
    width: "200",
    widthMobil: "150",
    heightMobil: "33",
    height: "47",
    domains: [],
  },
  {
    image:
      "https://res.cloudinary.com/pervolare-org/image/upload/v1696012953/spv/sites/images/new-home/partners/logo-dkv_yu4ons.png",
    alt: "icon DKV",
    width: "130",
    widthMobil: "105",
    heightMobil: "40",
    height: "50",
    domains: [],
  },
  {
    image:
      "https://res.cloudinary.com/pervolare-org/image/upload/v1710195486/spv/sites/images/new-home/partners/logo-con-ma_cdtyhn.webp",
    alt: "Logo Mundo Aventura",
    width: "130",
    widthMobil: "100",
    heightMobil: "",
    height: "",
    domains: ["co"],
  },
  {
    image:
      "https://res.cloudinary.com/pervolare-org/image/upload/v1722345599/spv/sites/images/new-home/partners/SALITRE_MAGICO_rec9tg.png",
    alt: "Logo Salitre Magico",
    width: "100",
    widthMobil: "100",
    heightMobil: "40",
    height: "50",
    domains: ["co"],
  },
  {
    image:
      "https://res.cloudinary.com/pervolare-org/image/upload/v1722345599/spv/sites/images/new-home/partners/Mesa_de_trabajo_3_xs3bhq.png",
    alt: "icon Booking Cars",
    width: "80",
    widthMobil: "80",
    heightMobil: "80",
    height: "80",
    domains: [],
  },
  {
    image:
      "https://res.cloudinary.com/pervolare-org/image/upload/v1722345599/spv/sites/images/new-home/partners/Logo_Connectivity_rawjck.png",
    alt: "icon Connectivity",
    width: "80",
    widthMobil: "80",
    heightMobil: "",
    height: "",
    domains: [],
  },
];
