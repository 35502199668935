import React from "react";
import { Section } from "./blogs.style.component";
import { t } from "i18next";
import { BLOGDATA } from "./blogs.data";
import { Carousel } from "../../libs";

export default class blogs extends React.Component {
  public domain: string;
  public currentDomainItems;

  componentDidMount() {
    window["env"].parameters.then((parameters) => {
      this.domain = parameters.domain;
      this.currentDomainItems =
        BLOGDATA.find((item) => item.domain === this.domain) ||
        BLOGDATA.find((item) => item.domain === "global");
      this.forceUpdate();
    });
  }

  render() {
    return (
      <Section className="mt-5" style={{ backgroundColor: "#E8F1FF" }}>
        <div className="container pt-5 pb-md-5">
          <div className="row d-content-center">
            <div className="col-xs-12 col-sm-12 col-md-3 col-lg-3">
              <p
                className="Gotham-bold f-20 f-md-28 f-lg-28 center-xs start-md mb-0"
                style={{ lineHeight: "30px" }}
              >
                {t("discover")}
              </p>
              <p className="Gotham-bold f-20 f-md-28 f-lg-28 center-xs start-md mb-0">
                {t("ourBlog")}
              </p>
              <div className="mt-lg-5 d-none d-lg-block">
                <a
                  href="/blog/home"
                  className="btn bg-blue py-2 px-3 f-16 f-lg-16 f-xl-16 text-white mt-3 mb-4 Gotham-bold"
                  style={{ borderRadius: 31, textDecoration: "none" }}
                >
                  {t("seeMoreArticles")}
                </a>
              </div>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-9 col-lg-9 d-none d-lg-block">
              <div className="row">
                {this.currentDomainItems &&
                  this.currentDomainItems.items.map((item, index) => (
                    <div
                      className="col-sm-6 col-md-6 col-lg-3 mb-3"
                      key={index}
                    >
                      <a href={item.link} target="_blank">
                        <div
                          className="lazyload card-blog"
                          style={{ backgroundImage: `url(${item.image})` }}
                        >
                          <div className="show-content">
                            <img
                              className="lazyload"
                              src={item.shade}
                              width={180}
                              height={225}
                              alt={item.alt}
                            />
                            <div>
                              <p className="text-white Gotham-bold f-13 mb-0">
                                {item.title}
                              </p>
                              <hr className="text-white" />
                              <p className="text-white Gotham f-13">
                                {item.date}
                              </p>
                            </div>
                          </div>
                        </div>
                      </a>
                    </div>
                  ))}
              </div>
            </div>
            <div className="col-xs-12 d-lg-none">
              <Carousel
                id="blog"
                automatic={true}
                interval={5000}
                swipe={true}
                mobileItems={2}
              >
                {this.currentDomainItems &&
                  this.currentDomainItems.items.map((item, index) => (
                    <div className="mr-3" key={index}>
                      <a href={item.link} target="_blank">
                        <div
                          className="lazyload card-blog"
                          style={{ backgroundImage: `url(${item.image})` }}
                        >
                          <div className="show-content">
                            <img
                              className="lazyload"
                              src={item.shade}
                              width={159}
                              height={193}
                              alt={item.alt}
                            />
                            <div>
                              <p className="text-white Gotham-bold f-10 mb-0">
                                {item.title}
                              </p>
                              <hr className="text-white" />
                              <p className="text-white Gotham f-10">
                                {item.date}
                              </p>
                            </div>
                          </div>
                        </div>
                      </a>
                    </div>
                  ))}
              </Carousel>
              <div className="mt-2 mt-md-5 center-xs d-lg-none">
                <a
                  href="/blog/home"
                  className="btn bg-blue py-2 px-3 f-16 f-lg-16 f-xl-16 text-white mt-3 mb-4 Gotham-bold"
                  style={{ borderRadius: 31, textDecoration: "none" }}
                >
                  {t("seeMoreArticles")}
                </a>
              </div>
            </div>
          </div>
        </div>
      </Section>
    );
  }
}
