// SwipeDetect.ts
export type SwipeDetectDir = "left" | "right";
export type SwipeDetectCallback = (swipedir: SwipeDetectDir) => void;

export class SwipeDetect {
  static swipe(el: HTMLElement, callback: SwipeDetectCallback): void {
    let touchsurface = el,
      startX: number,
      startY: number,
      distX: number,
      distY: number,
      threshold = 150,
      restraint = 100,
      allowedTime = 300,
      elapsedTime: number,
      startTime: number,
      handleswipe = callback;

    touchsurface.addEventListener(
      "touchstart",
      (e) => {
        let touchobj = e.changedTouches[0];
        distX = 0;
        startX = touchobj.pageX;
        startY = touchobj.pageY;
        startTime = new Date().getTime();
      },
      false
    );

    touchsurface.addEventListener("touchmove", (e) => {}, false);

    touchsurface.addEventListener(
      "touchend",
      (e) => {
        let touchobj = e.changedTouches[0];
        distX = touchobj.pageX - startX;
        distY = touchobj.pageY - startY;
        elapsedTime = new Date().getTime() - startTime;

        if (elapsedTime <= allowedTime) {
          if (Math.abs(distX) >= threshold && Math.abs(distY) <= restraint) {
            handleswipe(distX < 0 ? "left" : "right");
          }
        }
      },
      false
    );
  }
}
