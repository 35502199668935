import React from "react";
import { Section } from "./categories.plans.style.component";
import { t } from "i18next";
import { CATEGORIESPLANS } from "./categories-plans-data";
import { Carousel } from "../../libs";

export default class CategoriesPlans extends React.Component {
  public items = CATEGORIESPLANS;

  render() {
    return (
      <Section>
        <div className="container mt-5">
          <p className="text-center Gotham-bold f-xl-28 f-20 mt-4 mb-4 mb-md-5">
            {t("categoriesPlans.bestPlans")}
          </p>
          <div className="row">
            {this.items.map((item, index) => (
              <div
                className="col-xs-12 col-sm-12 col-md-3 col-lg-3 d-none d-lg-block"
                key={index.toString()}
              >
                <div className="card">
                  <div className="icon-card mt-3">
                    <img
                      className="lazyload"
                      src={item.icon}
                      style={{ zIndex: 1 }}
                      alt={item.title}
                      width={73}
                      height={73}
                    />
                    <p className="mb-0 Gotham-bold text-lh-blue f-xl-18 title-card">
                      {t(item.title)}
                    </p>
                  </div>
                  <img
                    className="mt-5 img-fluid-card lazyload"
                    src={item.image}
                    alt={item.title}
                    width={276}
                    height={181}
                  />
                  <div className="center-xs">
                    <a
                      href={item.link}
                      className="btn bg-blue py-1 px-5 f-14 f-lg-16 f-xl-16 text-white mt-3 mb-4 Gotham-bold"
                      style={{ borderRadius: 31, textDecoration: "none" }}
                    >
                      {t("categoriesPlans.SeePlans")}
                    </a>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="d-lg-none">
            <Carousel
              id="carouselCategoriesPlan"
              automatic={true}
              interval={5000}
              swipe={true}
            >
              {this.items.map((item, index) => (
                <div className="card" key={index}>
                  <div className="icon-card mt-3">
                    <img
                      className="lazyload"
                      src={item.icon}
                      style={{ zIndex: 1 }}
                      alt={item.title}
                      width={73}
                      height={73}
                    />
                    <p className="mb-0 Gotham-bold text-lh-blue f-xl-18 title-card">
                      {t(item.title)}
                    </p>
                  </div>
                  <img
                    className="mt-5 img-fluid-card lazyload"
                    src={item.image}
                    alt={item.title}
                    width={276}
                    height={181}
                  />
                  <div className="center-xs">
                    <a
                      href={item.link}
                      className="btn bg-blue py-1 px-5 f-14 f-lg-16 f-xl-16 text-white mt-3 mb-4 Gotham-bold"
                      style={{ borderRadius: 31, textDecoration: "none" }}
                    >
                      {t("categoriesPlans.SeePlans")}
                    </a>
                  </div>
                </div>
              ))}
            </Carousel>
          </div>
        </div>
      </Section>
    );
  }
}
