import styled from "styled-components";

export const Section = styled.section`
  .score {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .w-80 {
    width: 80%;
  }
  .icon-lg {
    padding-right: 39px;
    margin-left: -30px;
    margin-top: auto;
    margin-bottom: auto;
  }
  .icon-border {
    border-radius: 50px;
    padding: 4px 7px 4px 0px;
    background-color: #e8f1ff;
  }
  .b-card {
    height: 100%;
    border-radius: 25px;
    background-color: white;
    box-shadow: 0px 5px 12px rgb(0 0 0 / 25%);
  }
  #carouselRatings {
    padding-top: 60px;
  }
  .icon-border-xs {
    padding: 6px;
    width: 122px;
    height: 122px;
    margin-top: -77px;
    border-radius: 60px;
    background-color: #e8f1ff;
    box-shadow: 0px 1px 0px rgb(0 0 0 / 22%);
  }
  #carouselRatings .dots {
    display: none;
  }
  @media (min-width: 992px) {
  }
`;
