import React from "react";
import { Section } from "./our-alliances.style.component";
import { t } from "i18next";
import { OURALLIANCES } from "./our-alliances-data";
import { Carousel } from "../../libs";

export default class ourAlliances extends React.Component {
  render() {
    const items = OURALLIANCES.filter((item) => {
      if (!item.domains || item.domains.length === 0) return true;

      return item.domains.includes(localStorage.getItem("spv_topLevelDomain"));
    });

    return (
      <Section className="mt-5">
        <div className="container text-center">
          <p className="Gotham-bold f-20 f-md-28 f-lg-28 text-center mb-4">
            {t("ourAlliances")}
          </p>
          <div className="d-none d-lg-flex middle-xs center-xs">
            {items.map((item, index) => (
              <img
                className="mr-5 d-inline-block lazyload"
                src={item.image}
                alt={item.alt}
                key={index}
                width={item.width}
                height={item.height}
              />
            ))}
          </div>
          <div className="d-lg-none">
            <Carousel
              id="ourtAlliances"
              automatic={true}
              interval={5000}
              swipe={true}
              mobileItems={2}
            >
              {items.map((item, index) => (
                <img
                  className="lazyload"
                  src={item.image}
                  alt={item.alt}
                  key={index}
                  width={item.widthMobil}
                  height={item.heightMobil}
                />
              ))}
            </Carousel>
          </div>
          {/* <div className="center-xs mt-lg-4">
            <a
              href="/proveedores"
              className="btn bg-blue py-2 px-3 f-16 f-lg-16 f-xl-16 text-white mt-3 mb-4 Gotham-bold"
              style={{ borderRadius: 31, textDecoration: "none" }}
            >
              {t("seeAlliances")}
            </a>
          </div> */}
        </div>
      </Section>
    );
  }
}
