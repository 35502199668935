import React from "react";
import { Section } from "./why-do-you-need-help.style.component";
import { t } from "i18next";

export default class WhyDoYouNeedHelp extends React.Component {
  scrollToSpvQuote = () => {
    const spvQuoteElement = document.getElementById("spv-quote-latest-home");

    if (spvQuoteElement) spvQuoteElement.scrollIntoView({ behavior: "smooth" });
  };

  render() {
    return (
      <Section>
        <div className="container mt-5">
          <div className="row">
            <div className="col-12 col-sm-12 d-lg-none">
              <p className="f-20 Gotham-bold center-xs center-md px-5">
                {t("whySoYouNeedHelp.title")}
              </p>
            </div>
            <div className="col-12 col-sm-12 col-md-4 px-4 px-md-0 card-img-center">
              <img
                className="d-none d-md-block img-fluid-card-web"
                src="https://res.cloudinary.com/pervolare-org/image/upload/v1715923502/spv/sites/images/latest-home/ratings-review/need-help_tpqhut.webp"
                alt="¿Por qué necesitas ayuda?"
                width={340}
                height={340}
              />
              <img
                className="img-fluid-card d-md-none"
                src="https://res.cloudinary.com/pervolare-org/image/upload/v1715923502/spv/sites/images/latest-home/ratings-review/need-help_tpqhut.webp"
                alt="¿Por qué necesitas ayuda?"
                width={340}
                height={340}
              />
            </div>
            <div className="col-12 col-sm-12 col-md-8 px-4 px-md-0">
              <p className="f-20 f-md-28 f-lg-28 Gotham-bold d-none d-lg-block">
                {t("whySoYouNeedHelp.title")}
              </p>
              <p
                className="f-16 f-md-20 f-lg-18 f-16-why Gotham mt-4"
                style={{ textAlign: "justify" }}
              >
                {t("whySoYouNeedHelp.description")}
                <br className="d-lg-none" />
                <br className="d-lg-none" />
                {t("whySoYouNeedHelp.descriptionTwo")}
              </p>
              <div className="center-xs start-md start-lg">
                <button
                  className="btn btn-quote bg-blue py-2 px-4 f-16 text-white mt-lg-4 Gotham-bold"
                  style={{ borderRadius: 31, textDecoration: "none" }}
                  onClick={this.scrollToSpvQuote}
                >
                  {t("whySoYouNeedHelp.quote")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </Section>
    );
  }
}
