import styled from "styled-components";

export const Section = styled.section`
  #ourtAlliances {
    .prev,
    .next,
    .dots {
      display: none;
    }

    #carousel-slide {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`;
