export const CATEGORIESPLANS = [
  {
    icon: "https://res.cloudinary.com/pervolare-org/image/upload/v1714102291/spv/sites/images/latest-home/categories-plans/Group_1000005863_qgywzh.png",
    title: "Tourism",
    image:
      "https://res.cloudinary.com/pervolare-org/image/upload/v1717721124/spv/sites/images/latest-home/categories-plans/Imagen_Categoria_Turismo_WEBP_mfamni.webp",
    link: "/planes-coberturas",
  },
  {
    icon: "https://res.cloudinary.com/pervolare-org/image/upload/v1714102306/spv/sites/images/latest-home/categories-plans/Group_1000005864_ve6pkm.png",
    title: "Students",
    image:
      "https://res.cloudinary.com/pervolare-org/image/upload/v1717721124/spv/sites/images/latest-home/categories-plans/Imagen_Categoria_Estudiantil_WEBP_mefers.webp",
    link: "/planes-coberturas",
  },
  {
    icon: "https://res.cloudinary.com/pervolare-org/image/upload/v1714102316/spv/sites/images/latest-home/categories-plans/Group_1000005865_fmttdn.png",
    title: "Business",
    image:
      "https://res.cloudinary.com/pervolare-org/image/upload/v1717721124/spv/sites/images/latest-home/categories-plans/Imagen_Categoria_Negocios_WEBP_anapn9.webp",
    link: "/planes-coberturas",
  },
  {
    icon: "https://res.cloudinary.com/pervolare-org/image/upload/v1714102327/spv/sites/images/latest-home/categories-plans/Group_1000005866_bqhzft.png",
    title: "Elderly",
    image:
      "https://res.cloudinary.com/pervolare-org/image/upload/v1717721125/spv/sites/images/latest-home/categories-plans/Imagen_Categoria_Adulto_mayor_WEBP_qnqfsj.webp",
    link: "/planes-coberturas",
  },
];
