import styled from "styled-components";

export const Section = styled.section`
  .card-img-center {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .img-fluid-card {
    width: 85%;
    height: 95%;
  }
  .btn-quote {
    width: 60%;
  }
  @media (max-width: 1024px) {
    .img-fluid-card-web {
      width: 295px;
      height: auto;
    }
    .f-16-why {
      font-size: 16px !important;
    }
  }
  @media (min-width: 992px) {
    .img-fluid-card {
      width: 340px;
      height: 340px;
    }
    .btn-quote {
      width: 256px;
    }
    .card-img-center {
      display: flex;
      justify-content: start;
      align-items: start;
    }
  }
`;
