import styled from "styled-components";

export const Section = styled.section`
  .card-office {
    width: auto;
    height: 200px;
    display: flex;
    align-items: end;
    border-radius: 10px;
  }
  .card-name-city {
    background-color: white;
    padding: 5px;
    float: right;
    margin-top: -108px;
    border-radius: 4px;
  }
  #offices .dots {
    margin-top: 10px;
    display: none;
  }
  #offices .prev,
  .next {
    color: black;
  }
  @media (min-width: 992px) {
    #offices .dots {
      margin-top: 10px;
      display: block;
    }
    #offices .prev,
    .next {
      background-color: #c4c4c4;
      border: none;
    }
    .card-office {
      width: auto;
      height: 200px;
      display: flex;
      align-items: end;
      border-radius: 10px;
    }
  }
`;
