import React from "react";
import {
  NextButton,
  PrevButton,
  SlideshowContainer,
  CarouselWrapper,
  Slide,
  Dot,
} from "./carousel.style-component";
import { SwipeDetect } from "./swipedetect";

export type CarouselProps = {
  id: string;
  interval?: number;
  automatic?: boolean;
  swipe?: boolean;
  mobileXs?: number;
  mobileItems?: number;
  tabletItems?: number;
  webItems?: number;
};

type CarouselState = {
  slideIndex: number;
  numItemsToShow: number;
};

export class Carousel extends React.Component<CarouselProps, CarouselState> {
  private interval: number;
  private automatic: boolean;
  private timeoutId: NodeJS.Timeout | null = null;

  constructor(props: CarouselProps) {
    super(props);

    this.state = {
      slideIndex: 0,
      numItemsToShow: this.getNumItemsToShow(window.innerWidth),
    };

    this.interval = props.interval ?? 5000;
    this.automatic = props.automatic ?? false;
  }

  componentDidMount() {
    this.showSlides(this.state.slideIndex);

    if (this.props.swipe) {
      const slideContainer = document.getElementById(this.props.id);

      SwipeDetect.swipe(slideContainer, (swipeDir: string) => {
        this.plusSlides(swipeDir === "right" ? -1 : 1, true);
      });
    }

    window.addEventListener("resize", this.handleResize);

    if (this.automatic) {
      this.startAutoPlay();
    }
  }

  componentWillUnmount() {
    this.stopAutoPlay();
    window.removeEventListener("resize", this.handleResize);
  }

  handleResize = () => {
    this.setState({
      numItemsToShow: this.getNumItemsToShow(window.innerWidth),
    });
  };

  getNumItemsToShow = (width: number) => {
    const {
      mobileXs = 1,
      mobileItems = 1.5,
      tabletItems = 2.5,
      webItems = 3.5,
    } = this.props;

    if (width < 325) {
      return mobileXs;
    } else if (width < 768) {
      return mobileItems;
    } else if (width < 1024) {
      return tabletItems;
    } else {
      return webItems;
    }
  };

  plusSlides = (n: number, ifClicked: boolean) => {
    if (ifClicked) {
      this.stopAutoPlay();
    }

    const { children } = this.props;
    const { slideIndex } = this.state;
    const numChildren = React.Children.toArray(children).length;

    this.setState(
      (prevState) => ({
        slideIndex: (prevState.slideIndex + n + numChildren) % numChildren,
      }),
      () => {
        if (this.automatic) {
          this.startAutoPlay();
        }
      }
    );
  };

  currentSlide = (n: number) => {
    this.setState({
      slideIndex: n,
    });
  };

  showSlides = (n: number) => {
    if (this.automatic) {
      this.timeoutId = setTimeout(
        () => this.plusSlides(1, false),
        this.interval
      );
    }
  };

  startAutoPlay = () => {
    this.stopAutoPlay();
    this.timeoutId = setTimeout(() => this.plusSlides(1, false), this.interval);
  };

  stopAutoPlay = () => {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
      this.timeoutId = null;
    }
  };

  render() {
    const { children, id } = this.props;
    const { slideIndex, numItemsToShow } = this.state;

    const childrenArray = React.Children.toArray(children);
    const numChildren = childrenArray.length;

    return (
      <CarouselWrapper id={id}>
        <SlideshowContainer
          style={{
            transform: `translateX(-${(slideIndex * 100) / numItemsToShow}%)`,
            transition: "transform 0.5s",
          }}
        >
          {childrenArray.map((child, index) => (
            <Slide
              id="carousel-slide"
              key={index}
              style={{
                flex: `0 0 ${100 / numItemsToShow}%`,
                maxWidth: `${100 / numItemsToShow}%`,
              }}
            >
              {child}
            </Slide>
          ))}
          {numChildren > numItemsToShow &&
            childrenArray.slice(0, numItemsToShow).map((child, index) => (
              <Slide
                id="carousel-slide"
                key={index + numChildren}
                style={{
                  flex: `0 0 ${100 / numItemsToShow}%`,
                  maxWidth: `${100 / numItemsToShow}%`,
                }}
              >
                {child}
              </Slide>
            ))}
        </SlideshowContainer>
        <PrevButton className="prev" onClick={() => this.plusSlides(-1, true)}>
          <span>&#10094;</span>
        </PrevButton>
        <NextButton className="next" onClick={() => this.plusSlides(1, true)}>
          <span>&#10095;</span>
        </NextButton>
        <div className="dots" style={{ textAlign: "center" }}>
          {React.Children.map(children, (child, index) => (
            <Dot
              key={index}
              className={index === slideIndex % numChildren ? "active" : ""}
              onClick={() => this.currentSlide(index)}
            />
          ))}
        </div>
      </CarouselWrapper>
    );
  }
}
