export const BLOGDATA = [
  {
    domain: "ve",
    items: [
      {
        image:
          "https://res.cloudinary.com/pervolare-org/image/upload/v1715213061/spv/sites/images/latest-home/blog/item-1_brag44.webp",
        shade:
          "https://res.cloudinary.com/pervolare-org/image/upload/v1715213061/spv/sites/images/latest-home/blog/shade-down_gf7whq.webp",
        title: "¿Qué requisitos necesito para entrar a España?",
        date: "7 may 2024",
        link: "https://seguroparaviaje.com/blog/articulo/estos-hoy-requisitos-para-entrar-a-espana-los",
        alt: "shade",
      },
      {
        image:
          "https://res.cloudinary.com/pervolare-org/image/upload/v1715213061/spv/sites/images/latest-home/blog/item-2_qd3es3.webp",
        shade:
          "https://res.cloudinary.com/pervolare-org/image/upload/v1715213061/spv/sites/images/latest-home/blog/shade-up_axkxvn.webp",
        title: "¿Es necesario tener un seguro de viaje para Europa?",
        date: "11 January 2024",
        link: "https://seguroparaviaje.com/blog/articulo/lo-necesario-seguro-europa",
        alt: "shade",
      },
      {
        image:
          "https://res.cloudinary.com/pervolare-org/image/upload/v1715213061/spv/sites/images/latest-home/blog/item-3_c36hfx.webp",
        shade:
          "https://res.cloudinary.com/pervolare-org/image/upload/v1715213061/spv/sites/images/latest-home/blog/shade-down_gf7whq.webp",
        title: "Requisitos para viajar a Estados Unidos por primera vez",
        date: "7 May 2024",
        link: "https://seguroparaviaje.com/blog/articulo/los-requisitos-estados-unidos-por-primera-vez",
        alt: "shade",
      },
      {
        image:
          "https://res.cloudinary.com/pervolare-org/image/upload/v1715213061/spv/sites/images/latest-home/blog/item-4_uzdzum.webp",
        shade:
          "https://res.cloudinary.com/pervolare-org/image/upload/v1715213061/spv/sites/images/latest-home/blog/shade-up_axkxvn.webp",
        title: "5 mejores destinos para visitar en Europa",
        date: "29 January 2024",
        link: "https://seguroparaviaje.com/blog/articulo/los-mejores-destinos-en-europa",
        alt: "shade",
      },
    ],
  },
  {
    domain: "co",
    items: [
      {
        image:
          "https://res.cloudinary.com/pervolare-org/image/upload/v1715213061/spv/sites/images/latest-home/blog/item-1_brag44.webp",
        shade:
          "https://res.cloudinary.com/pervolare-org/image/upload/v1715213061/spv/sites/images/latest-home/blog/shade-down_gf7whq.webp",
        title: "¿Qué requisitos necesito para entrar a España?",
        date: "7 may 2024",
        link: "https://seguroparaviaje.com.co/blog/articulo/hoy-requisitos-para-entrar-a-espana",
        alt: "shade",
      },
      {
        image:
          "https://res.cloudinary.com/pervolare-org/image/upload/v1715213061/spv/sites/images/latest-home/blog/item-2_qd3es3.webp",
        shade:
          "https://res.cloudinary.com/pervolare-org/image/upload/v1715213061/spv/sites/images/latest-home/blog/shade-up_axkxvn.webp",
        title: "¿Es necesario tener un seguro de viaje para Europa?",
        date: "11 January 2024",
        link: "https://seguroparaviaje.com.co/blog/articulo/es-necesario-un-seguro-de-viaje-visitar-europa",
        alt: "shade",
      },
      {
        image:
          "https://res.cloudinary.com/pervolare-org/image/upload/v1715213061/spv/sites/images/latest-home/blog/item-3_c36hfx.webp",
        shade:
          "https://res.cloudinary.com/pervolare-org/image/upload/v1715213061/spv/sites/images/latest-home/blog/shade-down_gf7whq.webp",
        title: "Requisitos para viajar a Estados Unidos por primera vez",
        date: "7 May 2024",
        link: "https://seguroparaviaje.com.co/blog/articulo/conoce-requisitos-viajar-estados-unidos",
        alt: "shade",
      },
      {
        image:
          "https://res.cloudinary.com/pervolare-org/image/upload/v1715213061/spv/sites/images/latest-home/blog/item-4_uzdzum.webp",
        shade:
          "https://res.cloudinary.com/pervolare-org/image/upload/v1715213061/spv/sites/images/latest-home/blog/shade-up_axkxvn.webp",
        title: "5 mejores destinos para visitar en Europa",
        date: "29 January 2024",
        link: "https://seguroparaviaje.com.co/blog/articulo/top-mejores-destinos-conocer-en-europa",
        alt: "shade",
      },
    ],
  },
  {
    domain: "pe",
    items: [
      {
        image:
          "https://res.cloudinary.com/pervolare-org/image/upload/v1715213061/spv/sites/images/latest-home/blog/item-1_brag44.webp",
        shade:
          "https://res.cloudinary.com/pervolare-org/image/upload/v1715213061/spv/sites/images/latest-home/blog/shade-down_gf7whq.webp",
        title: "¿Qué requisitos necesito para entrar a España?",
        date: "7 may 2024",
        link: "https://seguroparaviaje.com.pe/blog/blog",
        alt: "shade",
      },
      {
        image:
          "https://res.cloudinary.com/pervolare-org/image/upload/v1715213061/spv/sites/images/latest-home/blog/item-2_qd3es3.webp",
        shade:
          "https://res.cloudinary.com/pervolare-org/image/upload/v1715213061/spv/sites/images/latest-home/blog/shade-up_axkxvn.webp",
        title: "¿Es necesario tener un seguro de viaje para Europa?",
        date: "11 January 2024",
        link: "https://seguroparaviaje.com.pe/blog/articulo/necesario-seguro-europa-peru",
        alt: "shade",
      },
      {
        image:
          "https://res.cloudinary.com/pervolare-org/image/upload/v1715213061/spv/sites/images/latest-home/blog/item-3_c36hfx.webp",
        shade:
          "https://res.cloudinary.com/pervolare-org/image/upload/v1715213061/spv/sites/images/latest-home/blog/shade-down_gf7whq.webp",
        title: "Requisitos para viajar a Estados Unidos por primera vez",
        date: "7 May 2024",
        link: "https://seguroparaviaje.com.pe/blog/articulo/los-requisitos-estados-unidos-primera-vez-peru",
        alt: "shade",
      },
      {
        image:
          "https://res.cloudinary.com/pervolare-org/image/upload/v1715213061/spv/sites/images/latest-home/blog/item-4_uzdzum.webp",
        shade:
          "https://res.cloudinary.com/pervolare-org/image/upload/v1715213061/spv/sites/images/latest-home/blog/shade-up_axkxvn.webp",
        title: "5 mejores destinos para visitar en Europa",
        date: "29 January 2024",
        link: "https://seguroparaviaje.com.pe/blog/articulo/mejores-destinos-europa-peruanos",
        alt: "shade",
      },
    ],
  },
  {
    domain: "ec",
    items: [
      {
        image:
          "https://res.cloudinary.com/pervolare-org/image/upload/v1715213061/spv/sites/images/latest-home/blog/item-1_brag44.webp",
        shade:
          "https://res.cloudinary.com/pervolare-org/image/upload/v1715213061/spv/sites/images/latest-home/blog/shade-down_gf7whq.webp",
        title: "¿Qué requisitos necesito para entrar a España?",
        date: "7 may 2024",
        link: "https://seguroparaviaje.com.ec/blog/articulo/conoce-requisitos-ingreso-espana",
        alt: "shade",
      },
      {
        image:
          "https://res.cloudinary.com/pervolare-org/image/upload/v1715213061/spv/sites/images/latest-home/blog/item-2_qd3es3.webp",
        shade:
          "https://res.cloudinary.com/pervolare-org/image/upload/v1715213061/spv/sites/images/latest-home/blog/shade-up_axkxvn.webp",
        title: "¿Es necesario tener un seguro de viaje para Europa?",
        date: "11 January 2024",
        link: "https://seguroparaviaje.com.ec/blog/articulo/seguro-de-viaje-obligatorio-es-o-no",
        alt: "shade",
      },
      {
        image:
          "https://res.cloudinary.com/pervolare-org/image/upload/v1715213061/spv/sites/images/latest-home/blog/item-3_c36hfx.webp",
        shade:
          "https://res.cloudinary.com/pervolare-org/image/upload/v1715213061/spv/sites/images/latest-home/blog/shade-down_gf7whq.webp",
        title: "Requisitos para viajar a Estados Unidos por primera vez",
        date: "7 May 2024",
        link: "https://seguroparaviaje.com.ec/blog/articulo/los-requisitos-estados-unidos-primera",
        alt: "shade",
      },
      {
        image:
          "https://res.cloudinary.com/pervolare-org/image/upload/v1715213061/spv/sites/images/latest-home/blog/item-4_uzdzum.webp",
        shade:
          "https://res.cloudinary.com/pervolare-org/image/upload/v1715213061/spv/sites/images/latest-home/blog/shade-up_axkxvn.webp",
        title: "5 mejores destinos para visitar en Europa",
        date: "29 January 2024",
        link: "https://seguroparaviaje.com.ec/blog/articulo/visita-los-mejores-destinos-cinco-en-europa-mundo",
        alt: "shade",
      },
    ],
  },
  {
    domain: "cl",
    items: [
      {
        image:
          "https://res.cloudinary.com/pervolare-org/image/upload/v1715213061/spv/sites/images/latest-home/blog/item-1_brag44.webp",
        shade:
          "https://res.cloudinary.com/pervolare-org/image/upload/v1715213061/spv/sites/images/latest-home/blog/shade-down_gf7whq.webp",
        title: "¿Qué requisitos necesito para entrar a España?",
        date: "7 may 2024",
        link: "https://seguroparaviaje.cl/blog/articulo/chile-hoy-requisitos-para-entrar-a-espana",
        alt: "shade",
      },
      {
        image:
          "https://res.cloudinary.com/pervolare-org/image/upload/v1715213061/spv/sites/images/latest-home/blog/item-2_qd3es3.webp",
        shade:
          "https://res.cloudinary.com/pervolare-org/image/upload/v1715213061/spv/sites/images/latest-home/blog/shade-up_axkxvn.webp",
        title: "¿Es necesario tener un seguro de viaje para Europa?",
        date: "11 January 2024",
        link: "https://seguroparaviaje.cl/blog/articulo/todo-lo-necesario-seguro-europa-chile",
        alt: "shade",
      },
      {
        image:
          "https://res.cloudinary.com/pervolare-org/image/upload/v1715213061/spv/sites/images/latest-home/blog/item-3_c36hfx.webp",
        shade:
          "https://res.cloudinary.com/pervolare-org/image/upload/v1715213061/spv/sites/images/latest-home/blog/shade-down_gf7whq.webp",
        title: "Requisitos para viajar a Estados Unidos por primera vez",
        date: "7 May 2024",
        link: "https://seguroparaviaje.cl/blog/articulo/los-requisitos-estados-unidos-primera-vez-chile",
        alt: "shade",
      },
      {
        image:
          "https://res.cloudinary.com/pervolare-org/image/upload/v1715213061/spv/sites/images/latest-home/blog/item-4_uzdzum.webp",
        shade:
          "https://res.cloudinary.com/pervolare-org/image/upload/v1715213061/spv/sites/images/latest-home/blog/shade-up_axkxvn.webp",
        title: "5 mejores destinos para visitar en Europa",
        date: "29 January 2024",
        link: "https://seguroparaviaje.cl/blog/articulo/mejores-destinos-europa-chile",
        alt: "shade",
      },
    ],
  },
  {
    domain: "mx",
    items: [
      {
        image:
          "https://res.cloudinary.com/pervolare-org/image/upload/v1715213061/spv/sites/images/latest-home/blog/item-1_brag44.webp",
        shade:
          "https://res.cloudinary.com/pervolare-org/image/upload/v1715213061/spv/sites/images/latest-home/blog/shade-down_gf7whq.webp",
        title: "¿Qué requisitos necesito para entrar a España?",
        date: "7 may 2024",
        link: "https://seguroparaviaje.com.mx/blog/articulo/cumplir-los-espana-para-requisitos-entrar-problema",
        alt: "shade",
      },
      {
        image:
          "https://res.cloudinary.com/pervolare-org/image/upload/v1715213061/spv/sites/images/latest-home/blog/item-2_qd3es3.webp",
        shade:
          "https://res.cloudinary.com/pervolare-org/image/upload/v1715213061/spv/sites/images/latest-home/blog/shade-up_axkxvn.webp",
        title: "¿Es necesario tener un seguro de viaje para Europa?",
        date: "11 January 2024",
        link: "https://seguroparaviaje.com.mx/blog/articulo/si-o-necesario-seguro-viaje-de-si-co",
        alt: "shade",
      },
      {
        image:
          "https://res.cloudinary.com/pervolare-org/image/upload/v1715213061/spv/sites/images/latest-home/blog/item-3_c36hfx.webp",
        shade:
          "https://res.cloudinary.com/pervolare-org/image/upload/v1715213061/spv/sites/images/latest-home/blog/shade-down_gf7whq.webp",
        title: "Requisitos para viajar a Estados Unidos por primera vez",
        date: "7 May 2024",
        link: "https://seguroparaviaje.com.mx/blog/articulo/primera-vez-requisitos-estados-unidos",
        alt: "shade",
      },
      {
        image:
          "https://res.cloudinary.com/pervolare-org/image/upload/v1715213061/spv/sites/images/latest-home/blog/item-4_uzdzum.webp",
        shade:
          "https://res.cloudinary.com/pervolare-org/image/upload/v1715213061/spv/sites/images/latest-home/blog/shade-up_axkxvn.webp",
        title: "5 mejores destinos para visitar en Europa",
        date: "29 January 2024",
        link: "https://seguroparaviaje.com.mx/blog/articulo/descubre-los-mejores-destinos-visitantes-europa",
        alt: "shade",
      },
    ],
  },
  {
    domain: "global",
    items: [
      {
        image:
          "https://res.cloudinary.com/pervolare-org/image/upload/v1715213061/spv/sites/images/latest-home/blog/item-1_brag44.webp",
        shade:
          "https://res.cloudinary.com/pervolare-org/image/upload/v1715213061/spv/sites/images/latest-home/blog/shade-down_gf7whq.webp",
        title: "¿Qué requisitos necesito para entrar a España?",
        date: "7 may 2024",
        link: "https://seguroparaviaje.com/blog/articulo/estos-hoy-requisitos-para-entrar-a-espana-los",
        alt: "shade",
      },
      {
        image:
          "https://res.cloudinary.com/pervolare-org/image/upload/v1715213061/spv/sites/images/latest-home/blog/item-2_qd3es3.webp",
        shade:
          "https://res.cloudinary.com/pervolare-org/image/upload/v1715213061/spv/sites/images/latest-home/blog/shade-up_axkxvn.webp",
        title: "¿Es necesario tener un seguro de viaje para Europa?",
        date: "11 January 2024",
        link: "https://seguroparaviaje.com/blog/articulo/lo-necesario-seguro-europa",
        alt: "shade",
      },
      {
        image:
          "https://res.cloudinary.com/pervolare-org/image/upload/v1715213061/spv/sites/images/latest-home/blog/item-3_c36hfx.webp",
        shade:
          "https://res.cloudinary.com/pervolare-org/image/upload/v1715213061/spv/sites/images/latest-home/blog/shade-down_gf7whq.webp",
        title: "Requisitos para viajar a Estados Unidos por primera vez",
        date: "7 May 2024",
        link: "https://seguroparaviaje.com/blog/articulo/los-requisitos-estados-unidos-por-primera-vez",
        alt: "shade",
      },
      {
        image:
          "https://res.cloudinary.com/pervolare-org/image/upload/v1715213061/spv/sites/images/latest-home/blog/item-4_uzdzum.webp",
        shade:
          "https://res.cloudinary.com/pervolare-org/image/upload/v1715213061/spv/sites/images/latest-home/blog/shade-up_axkxvn.webp",
        title: "5 mejores destinos para visitar en Europa",
        date: "29 January 2024",
        link: "https://seguroparaviaje.com/blog/articulo/los-mejores-destinos-en-europa",
        alt: "shade",
      },
    ],
  },
];
