import Modal, { ModalSize } from "../../libs/modal/modal.component";
import * as React from "react";
const { Suspense } = React;
import { ModalContent, Section, SectionSize } from "./banner.style-component";
import CarouselBanner from "../../libs/carousel-banner/carousel-banner.component";

export class Banner extends React.Component {
  private enviroment = (window as any).enviroment ?? "-qa";
  private parameters: any = null;
  private contactButton: any = null;
  public phone: string = "";
  private salesPersonDiscountCode = "";
  private bannerData;
  private countryOriginModalData = null;

  state = {
    imagesCarousel: null,
  };

  openDialog() {
    window.dispatchEvent(new Event("openModal[quote-modal]"));
  }

  componentDidMount() {
    const host = `https://web-spv${this.enviroment}-microfrontend.pervolare.net`;

    // contact Button Component
    const contactButtonComponent = document.createElement("script");
    contactButtonComponent.type = "module";
    contactButtonComponent.src = `${host}/contact-button/spv-contact-button.bundled.js`;
    // contactButtonComponent.src = 'http://localhost:4201/spv-contact-button.bundled.js'; // Para pruebas locales
    document.body.appendChild(contactButtonComponent);

    window["loadQuote"] = () => {
      const scripts = document.getElementsByTagName("script");
      const element = scripts.item(scripts.length - 1);
      const script = document.createElement("script");
      script.type = "module";
      script.src = `${host}/quote/spv-quote.bundled.js?time=1707493193950`;
      // script.src = "http://localhost:4200/spv-quote.bundled.js";
      element.appendChild(script);
    };

    const scripts = document.getElementsByTagName("script");
    const element = scripts.item(scripts.length - 1);

    const script = document.createElement("script");
    script.type = "module";
    script.text = `
      import {io} from 'https://cdn.socket.io/4.3.2/socket.io.esm.min.js';
      window.io = io;
      window.loadQuote();
    `;
    element.appendChild(script);

    // Get discount code from URL
    const urlSearchParams = new URLSearchParams(window.location.search);
    this.salesPersonDiscountCode = urlSearchParams.get("code");

    // Get domain params
    window["env"].parameters.then((parameters) => {
      this.parameters = parameters;

      let whatsapp =
        (parameters.addresses && parameters.addresses.whatsapp) ||
        parameters.phone;
      whatsapp = whatsapp.replace(/ /g, "").replace("+", "");

      this.contactButton = (
        <spv-contact-button
          style={{ display: "none" }}
          phone={whatsapp}
          license="8647629"
        ></spv-contact-button>
      );

      if (!localStorage.getItem("closeCountryDomainPopup")) {
        this.countryDomainPopup();
      }

      this.bannerData = parameters.parameterBanners;
      this.setState({ imagesCarousel: this.bannerData });
      this.forceUpdate();
    });
  }

  render() {
    const { imagesCarousel } = this.state;
    return (
      <Section className="container-fluid pb-0 pb-lg-5">
        {imagesCarousel && (
          <div>
            <CarouselBanner images={imagesCarousel} autoPlay={true} />
          </div>
        )}
        <div className="row bg-mx-0">
          <div
            className="container padding-banner-mobil"
            style={{ zIndex: "2" }}
          >
            <SectionSize className="row bg-mx-0">
              <div className="col-xs-12 col-lg-4 mt-0 pt-md-0 my-md-auto px-0 px-md-2 px-lg-3 content-resposive">
                <div className="card">
                  <spv-quote
                    id="spv-quote-latest-home"
                    discount-code={
                      this.salesPersonDiscountCode ||
                      this.parameters?.code ||
                      ""
                    }
                  ></spv-quote>
                </div>
              </div>
            </SectionSize>
          </div>
        </div>

        <Suspense fallback={<div>Loading...</div>}>
          <Modal id="quote-modal" showCloseButton={false}>
            <ModalContent className="d-flex center-xs middle-xs">
              <div
                className="container-lg"
                style={{ width: "100%", marginTop: "2%" }}
              >
                <div className="row" style={{ width: "100%" }}>
                  <div className="col-xs-12  col-sm-12">
                    <div className="d-flex end-xs">
                      <button className="btn py-0 px-0">
                        <i className="f-25 text-secondary close-modal">
                          &times;
                        </i>
                      </button>
                    </div>
                  </div>
                  <div className="d-none d-md-block col-sm-6 my-5">
                    <img
                      className="lazyload"
                      data-src="https://res.cloudinary.com/pervolare-org/image/upload/v1645641604/spv/sites/images/home/banner-quote-web_1_krw8wp.png"
                      width="417"
                      height="370"
                      alt="Cotiza tu viaje"
                    />
                  </div>

                  <div
                    className="col-xs-12 col-sm-6 d-flex center-xs m-auto"
                    style={{
                      minHeight: "522px",
                      maxWidth: 354,
                    }}
                  >
                    <spv-quote
                      discount-code={
                        this.salesPersonDiscountCode ||
                        this.parameters?.code ||
                        ""
                      }
                    ></spv-quote>
                  </div>
                </div>
              </div>
            </ModalContent>
          </Modal>
        </Suspense>

        <Suspense fallback={<div>Loading...</div>}>
          <Modal id="country-domain-modal" size={ModalSize.sm}>
            <div
              className="container-fluid px-md-5 py-5"
              style={{ textAlign: "center" }}
            >
              <img className="" src={this.countryOriginModalData?.flag} />
              <h3>
                Estas en{" "}
                <b>Seguro para viaje {this.countryOriginModalData?.current}</b>
              </h3>

              <a
                href={this.countryOriginModalData?.url}
                className="btn my-3"
                style={{ backgroundColor: "#2855a0", color: "white" }}
              >
                Cotiza lo mismo en {this.countryOriginModalData?.suggested}
              </a>

              <div>
                <button
                  style={{ color: "#2855a0" }}
                  className="btn close-modal"
                >
                  Quedarme en {this.countryOriginModalData?.current}
                </button>
              </div>
            </div>
          </Modal>
        </Suspense>

        {this.contactButton}
      </Section>
    );
  }

  private countryDomainPopup() {
    // TODO: Change this
    window["env"].geo.then((geo) => {
      const domains = {
        ar: {
          name: "Argentina",
          url: "https://seguroparaviaje.com.ar/",
          flag: "https://res.cloudinary.com/pervolare-org/image/upload/v1718739830/TAS/footer/banderas/Flags_8_vmvvmb.png",
        },
        bz: {
          name: "Belice",
          url: "https://seguroparaviaje.bz/",
          flag: "https://res.cloudinary.com/pervolare-org/image/upload/v1691684196/TAS/footer/banderas/bandera_belice_c6etb7.png",
        },
        bo: {
          name: "Bolivia",
          url: "https://seguroparaviaje.com.bo/",
          flag: "https://res.cloudinary.com/pervolare-org/image/upload/v1718739835/TAS/footer/banderas/Flags_11_jwd7wa.png",
        },
        br: {
          name: "Brasil",
          url: "https://segurodeviageminternacional.com/",
          flag: "https://res.cloudinary.com/pervolare-org/image/upload/v1718739820/TAS/footer/banderas/flags_3_encarh.png",
        },
        cl: {
          name: "Chile",
          url: "https://seguroparaviaje.cl/",
          flag: "https://res.cloudinary.com/pervolare-org/image/upload/v1718739832/TAS/footer/banderas/Flags_9_gbvcgz.png",
        },
        co: {
          name: "Colombia",
          url: "https://seguroparaviaje.com.co/",
          flag: "https://res.cloudinary.com/pervolare-org/image/upload/v1718739820/TAS/footer/banderas/flags_2_wnvgqw.png",
        },
        cr: {
          name: "Costa Rica",
          url: "https://seguroparaviaje.cr/",
          flag: "https://res.cloudinary.com/pervolare-org/image/upload/v1718739837/TAS/footer/banderas/Flags_12_rgtrpy.png",
        },
        ec: {
          name: "Ecuador",
          url: "https://seguroparaviaje.com.ec/",
          flag: "https://res.cloudinary.com/pervolare-org/image/upload/v1718739825/TAS/footer/banderas/flags_5_tmqmst.png",
        },
        sv: {
          name: "El Salvador",
          url: "https://seguroparaviaje.com.sv/",
          flag: "https://res.cloudinary.com/pervolare-org/image/upload/v1718739847/TAS/footer/banderas/Flags_17_tp67kj.png",
        },
        us: {
          name: "Estados Unidos",
          url: "https://us.seguroparaviaje.com/",
          flag: "https://res.cloudinary.com/pervolare-org/image/upload/v1718739826/TAS/footer/banderas/flags_6_te29im.png",
        },
        es: {
          name: "España",
          url: "https://seguroparaviaje.es/",
          flag: "https://res.cloudinary.com/pervolare-org/image/upload/v1718739823/TAS/footer/banderas/flags_4_qt5kiu.png",
        },
        gt: {
          name: "Guatemala",
          url: "https://seguroparaviaje.com.gt/",
          flag: "https://res.cloudinary.com/pervolare-org/image/upload/v1718739841/TAS/footer/banderas/Flags_14_aqor0k.png",
        },
        hn: {
          name: "Honduras",
          url: "https://seguroparaviaje.com.hn/",
          flag: "https://res.cloudinary.com/pervolare-org/image/upload/v1718739842/TAS/footer/banderas/Flags_15_cuniev.png",
        },
        mx: {
          name: "México",
          url: "https://seguroparaviaje.com.mx/",
          flag: "https://res.cloudinary.com/pervolare-org/image/upload/v1718739845/TAS/footer/banderas/Flags_16_h7r4fc.png",
        },
        ni: {
          name: "Nicaragua",
          url: "https://seguroparaviaje.com.ni/",
          flag: "https://res.cloudinary.com/pervolare-org/image/upload/v1718739851/TAS/footer/banderas/Flags_19_qdbvzj.png",
        },
        pa: {
          name: "Panamá",
          url: "https://seguroparaviaje.com.pa/",
          flag: "https://res.cloudinary.com/pervolare-org/image/upload/v1718739848/TAS/footer/banderas/Flags_18_subcc0.png",
        },
        py: {
          name: "Paraguay",
          url: "https://seguroparaviaje.com.py/",
          flag: "https://res.cloudinary.com/pervolare-org/image/upload/v1718739837/TAS/footer/banderas/Flags_12_rgtrpy.png",
        },
        pe: {
          name: "Perú",
          url: "https://seguroparaviaje.com.pe/",
          flag: "https://res.cloudinary.com/pervolare-org/image/upload/v1718739554/TAS/footer/banderas/Flags_1_ujhxu0.png",
        },
        do: {
          name: "Rep. Dominicana",
          url: "https://seguroparaviaje.com.do/",
          flag: "https://res.cloudinary.com/pervolare-org/image/upload/v1718739839/TAS/footer/banderas/Flags_13_va06ei.png",
        },
        uy: {
          name: "Uruguay",
          url: "https://seguroparaviaje.uy/",
          flag: "https://res.cloudinary.com/pervolare-org/image/upload/v1718739828/TAS/footer/banderas/flags_7_vq6sy3.png",
        },
        ve: {
          name: "Venezuela",
          url: "https://seguroparaviaje.com.ve/",
          flag: "https://res.cloudinary.com/pervolare-org/image/upload/v1718739834/TAS/footer/banderas/Flags_10_ur2szf.png",
        },
      };
      const domain = this.parameters.domain.toLowerCase();
      const countryCode = (geo.code ?? "").toLowerCase();
      const isInDomainOfCountry = domain == countryCode;
      const countryHasDomain = !!domains[countryCode];
      const showModal = countryHasDomain && !isInDomainOfCountry;

      if (showModal) {
        this.countryOriginModalData = {
          url: domains[countryCode].url,
          flag: domains[countryCode].flag,
          current: this.parameters.country?.name,
          suggested: domains[countryCode].name,
        };
        setTimeout(() => {
          this.forceUpdate();
          window.dispatchEvent(new Event("openModal[country-domain-modal]"));
          localStorage.setItem("closeCountryDomainPopup", "true");
        }, 3000);
      }
    });
  }
}
