import React from "react";
import { Section } from "./assistance-information.style.component";
import { t } from "i18next";

export default class AssistanceInformation extends React.Component {
  render() {
    return (
      <Section>
        <div className="box-shadow-card pt-4 pb-3">
          <div className="container">
            <p className="text-center Gotham-bold f-20 f-md-28 f-lg-28 mb-3">
              {t("WhyChooseUs")}
            </p>
            <div className="row">
              <div className="col-xs-6 col-md-3 col-lg-3 mb-4">
                <div className="border-r-card border-card">
                  <div className="d-flex align-self">
                    <div className="mr-3 d-flex card-img-center">
                      <img
                        className="icon-one lazyload"
                        src={t("assistanceInformation.start.icon")}
                        alt={t("assistanceInformation.start.title")}
                        width={50}
                        height={50}
                      />
                    </div>
                    <div>
                      <p className="mb-0 Gotham-bold text-lh-blue f-xl-24 f-lg-20 f-16">
                        {t("assistanceInformation.start.title")}
                      </p>
                      <p className="mb-0 Gotham text-lh-blue f-xl-16 f-lg-11 f-13">
                        {t("assistanceInformation.start.description")}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xs-6 col-md-3 col-lg-3 mb-4">
                <div className="border-r-card border-card">
                  <div className="d-flex align-self">
                    <div className="mr-3 d-flex card-img-center">
                      <img
                        className="icon-card lazyload"
                        src={t("assistanceInformation.heart.icon")}
                        alt={t("assistanceInformation.heart.title")}
                      />
                    </div>
                    <div>
                      <p className="mb-0 Gotham-bold text-lh-blue f-xl-24 f-lg-20 f-16">
                        {t("assistanceInformation.heart.title")}
                      </p>
                      <p className="mb-0 Gotham text-lh-blue f-xl-16 f-lg-11 f-13">
                        {t("assistanceInformation.heart.description")}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xs-6 col-md-3 col-lg-3 mb-4">
                <div className="border-r-card border-card">
                  <div className="d-flex align-self">
                    <div className="mr-3 d-flex card-img-center">
                      <img
                        className="icon-card lazyload"
                        src={t("assistanceInformation.person.icon")}
                        alt={t("assistanceInformation.person.title")}
                      />
                    </div>
                    <div>
                      <p className="mb-0 Gotham-bold text-lh-blue f-xl-24 f-lg-20 f-16">
                        {t("assistanceInformation.person.title")}
                      </p>
                      <p className="mb-0 Gotham text-lh-blue f-xl-16 f-lg-11 f-13">
                        {t("assistanceInformation.person.description")}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xs-6 col-md-3 col-lg-3 mb-4">
                <div className="border-card">
                  <div className="d-flex align-self">
                    <div className="mr-3 d-flex card-img-center">
                      <img
                        className="icon-card lazyload"
                        src={t("assistanceInformation.write.icon")}
                        alt={t("assistanceInformation.write.title")}
                      />
                    </div>
                    <div>
                      <p className="mb-0 Gotham-bold text-lh-blue f-xl-24 f-lg-20 f-16">
                        {t("assistanceInformation.write.title")}
                      </p>
                      <p className="mb-0 Gotham text-lh-blue f-xl-16 f-lg-11 f-13">
                        {t("assistanceInformation.write.description")}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="shadow">
          <div className="container mt-5 pb-lg-4">
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <h1 className="f-xl-28 f-20 center-xs start-md start-lg Gotham-bold">
                  {t("assistanceInformation.emergency.title")}
                  <br className="d-none d-lg-block" />{" "}
                  {t("assistanceInformation.emergency.sub_title")}
                </h1>
                <div className="row mt-5">
                  <div className="col-xs-6 col-md-12 col-lg-12 mb-4">
                    <div className="d-emergency-icon">
                      <div className="mr-0 mr-lg-3 d-flex card-img-center">
                        <img
                          className="mb-3 mb-lg-0 lazyload b-flags"
                          src={t(
                            "assistanceInformation.emergency.item_one.icon"
                          )}
                          alt={t(
                            "assistanceInformation.emergency.item_one.title"
                          )}
                          width={40}
                          height={29}
                        />
                      </div>
                      <div>
                        <p className="mb-0 px-2 Gotham f-xl-20 f-lg-20 f-14 center-xs start-md start-lg">
                          {t("assistanceInformation.emergency.item_one.title")}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-xs-6 col-md-12 col-lg-12 mb-4">
                    <div className="d-emergency-icon">
                      <div className="mr-0 mr-lg-3 d-flex card-img-center">
                        <img
                          className="mb-3 mb-lg-0 lazyload b-flags"
                          src={t(
                            "assistanceInformation.emergency.item_two.icon"
                          )}
                          alt={t(
                            "assistanceInformation.emergency.item_two.title"
                          )}
                          width={40}
                          height={29}
                        />
                      </div>
                      <div>
                        <p className="mb-0 px-2 Gotham f-xl-20 f-lg-20 f-14 center-xs start-md start-lg">
                          {t("assistanceInformation.emergency.item_two.title")}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-xs-6 col-md-12 col-lg-12 mb-4">
                    <div className="d-emergency-icon">
                      <div className="mr-0 mr-lg-3 d-flex card-img-center">
                        <img
                          className="mb-3 mb-lg-0 lazyload b-flags"
                          src={t(
                            "assistanceInformation.emergency.item_three.icon"
                          )}
                          alt={t(
                            "assistanceInformation.emergency.item_three.title"
                          )}
                          width={40}
                          height={29}
                        />
                      </div>
                      <div>
                        <p className="mb-0 px-2 Gotham f-xl-20 f-lg-20 f-14 center-xs start-md start-lg">
                          {t(
                            "assistanceInformation.emergency.item_three.title"
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-xs-6 col-md-12 col-lg-12 mb-4">
                    <div className="d-emergency-icon">
                      <div className="mr-0 mr-lg-3 d-flex card-img-center">
                        <img
                          className="mb-3 mb-lg-0 lazyload b-flags"
                          src={t(
                            "assistanceInformation.emergency.item_four.icon"
                          )}
                          alt={t(
                            "assistanceInformation.emergency.item_four.title"
                          )}
                          width={40}
                          height={29}
                        />
                      </div>
                      <div>
                        <p className="mb-0 px-2 Gotham f-xl-20 f-lg-20 f-14 center-xs start-md start-lg">
                          {t("assistanceInformation.emergency.item_four.title")}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="center-xs start-md start-lg">
                  <a
                    href="/preguntas-frecuentes"
                    className="btn bg-blue py-2 px-4 f-16 text-white mt-lg-4 Gotham-bold"
                    style={{ borderRadius: 31, textDecoration: "none" }}
                  >
                    {t("assistanceInformation.emergency.btn")}
                  </a>
                </div>
              </div>
              <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 d-none d-lg-block">
                <img
                  className="img-emergency lazyload"
                  src={t("assistanceInformation.emergency.img")}
                  alt="emergency"
                  width={531}
                  height={409}
                />
              </div>
            </div>
          </div>
        </div>
      </Section>
    );
  }
}
