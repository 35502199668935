import styled from "styled-components";

export const ModalDialog = styled.div`
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1001; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgba(19, 20, 28, 0.88);

  /* The Modal Close Button (x) */
  .close {
    position: absolute;
    right: 35px;
    top: 15px;
    font-size: 40px;
    font-weight: bold;
    color: #f1f1f1;

    &:hover,
    &:focus {
      color: #242424;
      cursor: pointer;
    }
  }

  .modal-content {
    background-color: #fefefe;
    /* border: 1px solid #888; */
    border-radius: 0.5rem;
  }
`;
