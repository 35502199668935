import styled from "styled-components";

export const CarouselWrapper = styled.div`
  position: relative;
  overflow: hidden;
  max-width: 100%;
`;

export const SlideshowContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  transition: transform 0.5s ease;
`;

export const Slide = styled.div`
  flex: 0 0 calc(100% / 4);
  max-width: calc(100% / 4);
  box-sizing: border-box;
  padding: 10px;
`;

export const NextButton = styled.a`
  cursor: pointer;
  position: absolute;
  top: 50%;
  right: 0;
  margin-top: -22px;
  color: white;
  font-weight: bold;
  font-size: 21px;
  transition: background-color 0.3s;
  border-radius: 0 3px 3px 0;
  border: 1px solid black;
  user-select: none;
  border-radius: 50px;
  background-color: #c4c4c4;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.6;
  &:hover {
    background-color: #c4c4c4;
    opacity: 0.9;
  }
`;

export const PrevButton = styled.a`
  cursor: pointer;
  position: absolute;
  top: 50%;
  left: 0;
  margin-top: -22px;
  color: white;
  font-weight: bold;
  font-size: 21px;
  transition: background-color 0.3s;
  border-radius: 3px 0 0 3px;
  border: 1px solid black;
  user-select: none;
  border-radius: 50px;
  background-color: #c4c4c4;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.6;
  &:hover {
    background-color: #c4c4c4;
    opacity: 0.9;
  }
`;

export const Dot = styled.span`
  cursor: pointer;
  height: 15px;
  width: 15px;
  margin: 0 2px;
  background-color: #e1e1e1;
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.6s ease;

  &.active {
    background-color: #2855a0;
  }
`;
